import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { eAction } from '../enums/action.enum';
import { PermissionService } from '../services/permission/permission.service';

export const AUTH_REDIRECT_URL = '/auth';
export const UNAUTHORIZED_REDIRECT_URL = '/unauthorized';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard {
  private permissionService = inject(PermissionService);
  private router = inject(Router);

  canActivate(next: ActivatedRouteSnapshot): boolean {
    return this.handleAuthentication(next);
  }

  canActivateChild(next: ActivatedRouteSnapshot): boolean {
    return this.handleAuthentication(next);
  }

  canLoad(next: ActivatedRouteSnapshot): boolean {
    return this.handleAuthentication(next);
  }

  handleAuthentication(route: ActivatedRouteSnapshot): boolean {
    if (!route.data) return true;

    const requiredResource = route.data['resource'];
    const requiredAction = route.data['action'] || eAction.VIEW;

    if (requiredResource && requiredAction) {
      const hasRequiredUserRole = this.permissionService.hasPermission(requiredResource, requiredAction);
      if (!hasRequiredUserRole) {
        this.navigateTo(UNAUTHORIZED_REDIRECT_URL);
        return false;
      }
    }

    return true;
  }

  private navigateTo(route: string) {
    this.router.navigate([route], { queryParamsHandling: 'merge' });
    return false;
  }
}
